import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import Submit from '../components/Submit';
import Grid, { Auto, Full } from '../components/Grid';
import { stripEmptyStrings } from './user';
import {
    MdPersonPin,
    MdEmail,
    MdPhone,
} from 'react-icons/md';

const ContactForm = ({ action }) => (
    <Formik
        initialValues={{
            email: '',
            phone: '',
            name: '',
            comment: ''
        }}

        onSubmit={(variables, actions) =>
            action({ variables: stripEmptyStrings(variables) })
                .then(() => actions.resetForm())
        }

        render={() => (
            <Form>
                <Grid>
                    <Auto>
                        <label>
                            Name *
                            <Field name="name" type="text" required />
                            <MdPersonPin />
                        </label>
                    </Auto>
                    <Auto>
                        <label>
                            Email *
                            <Field name="email" type="email" required />
                            <MdEmail />
                        </label>
                    </Auto>
                    <Auto>
                        <label>
                            Phone
                            <Field name="phone" type="tel" />
                            <MdPhone />
                        </label>
                    </Auto>
                </Grid>
                <Grid>
                    <Full>
                        <label>
                            Comment
                            <Field name="comment" component="textarea" />
                        </label>
                    </Full>
                </Grid>
                <Submit text="Submit" />
            </Form>
        )}
    />);

ContactForm.propTypes = {
    action: PropTypes.func
};

export default ContactForm;