import React from "react";
import Helmet from "react-helmet";
import App from "./_app";
import { graphql } from "gatsby";
import { Mutation } from "react-apollo";
import ContactForm from "../forms/contact";
import { ContainerMedium } from "../components/Container";
import { CONTACT } from "..//queries/public";
import Alert from "../components/Alert";
import Wrap from "../components/Wrap";
import Hero from "../components/Hero";
import { NoStylelist } from "../components/Footer";

const Contact = ({ data }) => (
  <App>
    <Helmet>
      <title>Contact our Recruiters to Hire Talent or Find New IT Jobs</title>
      <meta
        name="description"
        content="Ready to jumpstart your career or find specialized IT talent? Speak with a recruitment specialist today or call us at 1-855-423-9265."
      />
    </Helmet>
    <Hero title={data.page.heading} image={data.page.image}>
      <p>Ready to start the conversation?</p>
      <hr />
      <NoStylelist>
        <li>
          Email:{" "}
          <a href={`mailTo:${data.site.siteMetadata.email}`}>
            {data.site.siteMetadata.email}
          </a>
        </li>
        <li>
          Toll-Free:{" "}
          <a href={`tel:${data.site.siteMetadata.tollFree}`}>
            {data.site.siteMetadata.tollFree}
          </a>
        </li>
        <li>
          Local:{" "}
          <a href={`tel:${data.site.siteMetadata.local}`}>
            {data.site.siteMetadata.local}
          </a>
        </li>
        <li>
          Fax:{" "}
          <a href={`tel:${data.site.siteMetadata.fax}`}>
            {data.site.siteMetadata.fax}
          </a>
        </li>
      </NoStylelist>
    </Hero>
    <Wrap muted>
      <ContainerMedium>
        <h1>Send Us a Message</h1>
        <Mutation mutation={CONTACT}>
          {(query, { error, data }) => (
            <div>
              <ContactForm action={query} />
              {error && (
                <Alert>Could not send the message. Please try again.</Alert>
              )}
              {data && (
                <Alert success>
                  Message received. Thank you and we will be in touch soon.
                </Alert>
              )}
            </div>
          )}
        </Mutation>
      </ContainerMedium>
    </Wrap>
  </App>
);

export default Contact;
export const query = graphql`
  query {
    page: contentfulPage(url: { eq: "contact" }) {
      heading
      image {
        title
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        local
        tollFree
        email
        fax
      }
    }
  }
`;
